



























































































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppModal from "@/components/core/AppModal.vue";

@Component({
  components: {
    AppButton,
    AppInput,
    AppLabel,
    AppModal,
  },
})
export default class AppCreateParcelModal extends Vue {
  parcel: pro.Parcel = {};

  add(): void {
    this.$emit("add-parcel", this.parcel);
    this.$emit("close");
  }
}
