

























































































































































import { Component, Vue } from "vue-property-decorator";
import AppAddressWidget from "@/components/widgets/AppAddressWidget.vue";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCreateParcelModal from "@/components/modals/AppCreateParcelModal.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppLineItem from "@/components/AppLineItem.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppParcel from "@/components/AppParcel.vue";
import AppSelect from "@/components/core/AppSelect.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import shipmentStore from "@/store/shipment";
import { currentUser } from "@/utils/authentication";
import { createErrorNotification, Notification } from "@/utils/notification";
import { createShipment } from "@/utils/api";
import { hasShipperPermissions } from "@/utils/authorization";

@Component({
  components: {
    AppAddressWidget,
    AppAlert,
    AppButton,
    AppCreateParcelModal,
    AppInput,
    AppLabel,
    AppLineItem,
    AppListGroup,
    AppListGroupItem,
    AppParcel,
    AppSelect,
    AppWidget,
    TheHeader,
    TheMain,
  },
})
export default class CreateShipment extends Vue {
  isShipper = false;
  notification: Notification | null = null;
  shipment: pro.Shipment | null = null;
  saving = false;
  showCreateParcelModal = false;

  get catalog(): pro.Catalog {
    return { id: this.$route.params.catalogId };
  }

  /**
   * Loads the Shipment.
   * The form element must be defined after the shipment is loaded. Otherwise,
   * the element does not yet exist on the DOM.
   */
  async mounted() {
    this.isShipper = await hasShipperPermissions(this.catalog);
    await this.loadShipment();
  }

  /**
   * Stores unsaved changes to the Shipment
   */
  beforeDestroy() {
    if (this.shipment) shipmentStore.shipment = this.shipment;
  }

  /**
   * Checks whether the shipment creation form button should be disabled.
   */
  disabled(): boolean {
    if (!this.shipment || !this.shipment.fromAddress) return true;
    else if (this.shipment.carrier == "PICKUP") return false;
    else if (!this.shipment.parcels || this.shipment.parcels.length < 1)
      return true;
    else if (!this.shipment.account || !this.shipment.service) return true;
    return false;
  }

  /**
   * Creates a new shipment.
   */
  async createShipment(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      if (!this.shipment || !this.shipment.lines || !this.shipment.catalog)
        throw new Error("Missing shipment details.");
      const shipment = await createShipment(this.shipment);
      shipmentStore.destroy();
      this.goToShipment(shipment);
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to create shipment",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  /**
   * Loads the shipment from storage
   */
  async loadShipment() {
    let shipment = shipmentStore.shipment;
    if (!shipment.fromAddress) {
      const defaultAddress = currentUser().defaultAddress;
      shipment = {
        ...shipmentStore.shipment,
        ...(defaultAddress && { fromAddress: defaultAddress }),
      };
    }
    shipment.parcels = [];
    this.shipment = shipment;
  }

  /**
   * Redirects to the Shipment details
   */
  goToShipment(shipment: pro.Shipment): void {
    const shipmentId = String(shipment.id);
    this.$router.push({ name: "SHIPMENT", params: { shipmentId } });
  }

  addParcel(parcel: pro.Parcel): void {
    this.shipment?.parcels?.push(parcel);
  }

  form(): HTMLFormElement | null {
    return document.getElementById("createShipment") as HTMLFormElement | null;
  }
}
